// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-gallery-companion-monuments-tsx": () => import("./../../../src/pages/gallery/companion-monuments.tsx" /* webpackChunkName: "component---src-pages-gallery-companion-monuments-tsx" */),
  "component---src-pages-gallery-etchings-tsx": () => import("./../../../src/pages/gallery/etchings.tsx" /* webpackChunkName: "component---src-pages-gallery-etchings-tsx" */),
  "component---src-pages-gallery-granite-colors-tsx": () => import("./../../../src/pages/gallery/granite-colors.tsx" /* webpackChunkName: "component---src-pages-gallery-granite-colors-tsx" */),
  "component---src-pages-gallery-individual-monuments-tsx": () => import("./../../../src/pages/gallery/individual-monuments.tsx" /* webpackChunkName: "component---src-pages-gallery-individual-monuments-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-staff-tsx": () => import("./../../../src/pages/staff.tsx" /* webpackChunkName: "component---src-pages-staff-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */)
}

